import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ItemList from '../../shared/ItemList.component'
import Container from '../wrappers/container-main.component'

import { ktmFoods } from '../../data/food.data.js'
import logo from '../../images/nav_logo.png'

import './food.css'
import Item from '../../shared/Item.component'

const Food = () => {
  return (
    <Container container={{ class: "item-list-container" }} id="food-container">
      <div className='item-list-logo'>
        <img src={logo} alt="uptop-logo" className='ktm-logo' />
      </div>
      <div className='disclaimer'>
        <p>
          <small>Before ordering, please inform our staff of any allergies or dietary restrictions you have. Our menu items may contain allergens or come into contact with them during preparation.Thank you for your cooperation in ensuring a safe dining experience.</small>
        </p>
      </div>
      <ItemList title="UPTOP FOOD" id="uptop-food">
        {ktmFoods.map(item => (
          <Item key={item.id} item={item} />
        ))}
      </ItemList>
    </Container>
  )
}

export default Food
