import React from 'react'
import karoakeInfoImg from '../../../images/karaoke-info.jpg'

import './karaoke.css'

const KaraokeInfo = () => {
  return (
    <div className='container karaoke-info-container'>
      <img className="karoake-info-img" src={karoakeInfoImg} alt="karaoke room info" loading='lazy' />
    </div>
  )
}

export default KaraokeInfo
