import React from 'react'
import './footer.css'

import logo from '../../images/nav_logo.png'

const Footer = () => {
  let date = new Date()
  let currentYear = date.getFullYear()
  return (
    <div className='section footer-section'>
      <div className='footer-ktm-logo'>
        <img src={logo} alt="ktm-logo" className='footer-ktm-logo-img' />
      </div>
      <div className='footer-heading'>
        <div className='social-medias'>
          <a href="https://www.facebook.com/uptop" alt="facebook">
            <i className="fa-brands fa-square-facebook"></i>
          </a>
          <a href="https://www.instagram.com/uptop.nyc/" alt="instagram">
            <i className="fa-brands fa-instagram"></i>
          </a>
        </div>
      </div>
      <div className='address-info'>
        <p>7532 BROADWAY FL 2</p>
        <p>ELMHURST, NY 11373</p>
        <a href="tel:929-424-300" className='ktm-footer-phone'>929-424-3001</a>
      </div>
      <div className='footer-copyright'>
        <p>&nbsp;Copyright © {currentYear} uptop. All rights reserved.{"  "} </p>
        <a href="https://www.bibashkc.com" target="_blank"><p>www.bibashkc.com </p></a>
      </div>
    </div>
  )
}

export default Footer
