import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Container from '../wrappers/container-main.component'
import logo from '../../images/nav_logo.png'
import ItemList from '../../shared/ItemList.component'

import { cocktails, beers, draftBeers, wines, signatureCocktails } from '../../data/drink.data'
import Item from '../../shared/Item.component'
import Image from '../../shared/components/Image.component'
import ktm_snack_deals from '../../images/snacks_deals.jpeg'

const Drink = () => {
  let { id: sectionId } = useParams()
  useEffect(() => {
    if (sectionId) {
      const ele = document.getElementById(sectionId)
      if (ele) {
        ele.scrollIntoView({ behavior: "smooth", vertical: 'start' })
      } else {
        const ele = document.getElementById('drink-container')
        ele.scrollIntoView({ behavior: "smooth", vertical: 'start' })
      }
    }
  }, [sectionId])
  return (
    <Container id="drink-container" container={{ class: "item-list-container" }}>
      <div className='item-list-logo'>
        <img src={logo} alt="ktm-logo" className='ktm-logo' />
      </div>
      <div className='disclaimer'>
        <p>
          <small>Alcoholic beverages are intended for customers of legal drinking age. Please drink responsibly.</small>
        </p>
      </div>
      {/* <Image
        imageDesktop={ktm_snack_deals}
        imageMobile={ktm_snack_deals}
      /> */}
      <ItemList title="Singature Cocktails" id="singature-cocktail">
        {signatureCocktails.map(item => (
          <Item key={item.id} item={item} />
        ))}
      </ItemList>
      <ItemList title="COCKTAILS" id="cocktail">
        {cocktails.map(item => (
          <Item key={item.id} item={item} />
        ))}
      </ItemList>
      <ItemList title="BEERS" id="beer">
        {beers.map(item => (
          <Item key={item.id} item={item} />
        ))}
      </ItemList>
      <ItemList title="DRAFT BEERS" id="draftBeer">
        {draftBeers.map(item => (
          <Item key={item.id} item={item} />
        ))}
      </ItemList>
      <ItemList title="WINES" id="wine">
        {wines.map(item => (
          <Item key={item.id} item={item} />
        ))}
      </ItemList>
      <div className='disclaimer'>
        <p>
          <small>We cannot guarantee the absence of allergens or cross-contamination in our drink ingredients. Please inform our staff of any allergies or dietary restrictions.</small>
        </p>
      </div>
    </Container>
  )
}

export default Drink
